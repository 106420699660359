import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import Header from '../components/header'

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    const getVideos = () => {
      return document.getElementsByClassName('video')
    }
    const getFirst = (element, className) =>
      element.getElementsByClassName(className)[0]
    const getStill = (element) => getFirst(element, 'video-still')
    const getGif = (element) => getFirst(element, 'video-gif')
    const hide = (element) => (element.style = 'display: none')

    const preventDefaultBehaviour = (event) => {
      event.stopImmediatePropagation()
      event.preventDefault()
      event.returnValue = false
    }

    const preventInteractionBehaviour = (video) => {
      video.addEventListener('touchstart', preventDefaultBehaviour, false)
      video.addEventListener('click', preventDefaultBehaviour, false)
    }

    const showStillOnInteraction = (video, container, still, gif) => {
      const show = () => {
        container.innerHTML = still
        showGifOnInteraction(video, container, still, gif)
      }

      video.addEventListener('mouseup', show, false)
      video.addEventListener('touchend', show, false)
    }

    const showGifOnInteraction = (video, container, still, gif) => {
      const show = () => {
        container.innerHTML = gif
        showStillOnInteraction(video, container, still, gif)
      }

      video.addEventListener('mouseup', show, false)
      video.addEventListener('touchend', show, false)
    }

    const videos = getVideos()
    for (let video of videos) {
      preventInteractionBehaviour(video)
      const still = getStill(video)
      const stillHtml = still.innerHTML
      const gif = getGif(video)
      const gifHtml = gif.innerHTML
      hide(gif)
      showGifOnInteraction(video, still, stillHtml, gifHtml)
    }
  }

  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const post = get(this.props, 'data.contentfulBlogPost')
    const [author] = get(this.props, 'data.allContentfulPerson.edges')

    return (
      <Layout author={author.node}>
        <Helmet
          title={`${post.title} | ${siteTitle}`}
          meta={[
            { name: 'description', content: post.description.description },
            { name: 'keywords', content: post.keywords },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header
          image={post.heroImage.file.url}
          author={author.node}
          headerOpacity={post.headerOpacity}
        >
          <div className="post-heading">
            <h1>{post.title}</h1>
            <p
              className="subheading"
              dangerouslySetInnerHTML={{
                __html: post.description.description,
              }}
            />
            <span className="meta">{post.publishDate}</span>
          </div>
        </Header>
        <article>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-8 col-md-10 mx-auto"
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      headerOpacity
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        file {
          url
        }
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      keywords
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          linkedIn
          twitter
          github
        }
      }
    }
  }
`
